import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import store from '@/store';
import Card from '@/components/Card';
import FetchData from '@/components/FetchData';
import { parseTime } from '@/public/methods';

import CommentList from '@/components/Article/CommentList';
import CommentPublish from '@/components/Article/CommentPublish';
import type { Component } from 'vue/types/umd';
import { req } from '@/api';

interface PhraseData {
	onlyid: string | number;
	content: string;
	time: number;
	readCount: number;
	praiseCount: number;
	commentCount: number;
	shareCount: number;
	from: string;
	[propName: string]: any;
}
interface CountInfo {
	key: string;
	value: string;
	operate: () => void;
	icon?: Component | (() => Promise<typeof import('*')>);
}
let isLoved = false;
export default defineComponent({
	components: { Card, FetchData, CommentPublish, CommentList },
	setup(props: any, { root }: any) {
		const { $message, $modal, $route } = root;
		const t = root.$t.bind(root);
		const phraseData: Ref<PhraseData> = ref({
			onlyid: 'aaaaaaaa',
			content: '未知内容未知内容',
			time: -1,
			readCount: -1,
			praiseCount: -1,
			commentCount: -1,
			shareCount: -1,
			from: 'IE',
		});
		const touchData = (data: PhraseData) => {
			phraseData.value = data;
		};
		const countInfo: Ref<CountInfo[]> = ref([
			{
				key: '浏览',
				value: 'readCount',
				operate() {
					$modal(() => '暂不支持查看访客信息');
				},
				icon: () => import('@/svg/eye'),
			},
			{
				key: '点赞',
				value: 'praiseCount',
				operate() {
					new Promise((resolve, reject) => {
						phraseData.value.praiseCount += 1;
						if (isLoved) {
							reject('您已经点过赞啦');
							return;
						}
						const { onlyid } = $route.params;
						req('SEND_LIKE', { tribe: 'phrase', onlyid })
							.then((data: any) => {
								resolve(data);
							})
							.catch(error => {
								reject(error);
							});
					})
						.then((data: any) => $message.success('点赞成功，感谢您的陪伴'))
						.catch(error => {
							$message.warning('' + error);
							phraseData.value.praiseCount -= 1;
						})
						.finally(() => (isLoved = true));
				},
				icon: () => import('@/svg/thumb-up'),
			},
			{
				key: '评论',
				value: 'commentCount',
				operate() {
					try {
						//document.documentElement.scrollTo(0, 9999);
						const cpfEl = document.querySelector('[data-comment-publish-form-area]');
						if (cpfEl) {
							const top = cpfEl.getBoundingClientRect().y + document.documentElement.scrollTop;
							document.documentElement.scrollTo(0, top);
						}
					} catch (e) {
						//
					}
					try {
						const commentEl = document.querySelector('[data-draft-wysiwyg-container]');
						if (commentEl) {
							const activeEl = commentEl.childNodes[0].childNodes[1];
							//console.log(activeEl);
							(activeEl as any).onclick();
						}
					} catch (e) {
						//
					}
				},
				icon: () => import('@/svg/commit'),
			},
			{
				key: '分享',
				value: 'shareCount',
				operate() {
					$modal(() => '暂不支持分享');
				},
				icon: () => import('@/svg/arrow'),
			},
		]);
		return { phraseData, touchData, parseTime, countInfo, t };
	},
});
